import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { lead_spool_panel } from './lead_spool_panel';

export const plugin = new PanelPlugin<SimpleOptions>(lead_spool_panel).setPanelOptions((builder) => {
    return builder.addSelect({
        path: 'panel_type',
        defaultValue: 'spool_config',
        name: 'Panel type',
        description: 'Change the panel type',
        settings: {
            options: [
                {
                    value: 'spool_config',
                    label: 'Lead Spool Configuration',
                },
                {
                    value: 'spool_analysis',
                    label: 'Lead Spool Analysis',
                },
                {
                    value: 'analysis_export',
                    label: 'Analysis Export Button',
                },
                {
                    value: 'code_export',
                    label: 'Code Export Button',
                },
                {
                    value: 'all_spool_export',
                    label: 'All Spool Export Button',
                },
            ],
        },
    });
});
