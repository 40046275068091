export class mqtt_client_t {
    c;
    topic;

    constructor(host, topic, options) {
        const mqtt = require('mqtt/dist/mqtt');
        this.topic = topic;
        this.c = mqtt.connect(host, options);
        this.c.on('connect', () => {
            this.on_connect(this.topic);
        });
        this.c.on('error', this.on_error);
        this.c.on('reconnect', this.on_reconnect);
        this.c.on('message', this.on_message);
    }

    on_connect(topic) {
        console.log('Connecting mqtt client');
        let err_;
        this.c.subscribe(topic, function (err) {
            err_ = err;
        });
        if (!err_) {
            console.log('connected to topic %s', topic);
        }
    }

    on_error(err) {
        console.log('Connection error: ', err);
        this.c.end();
    }

    on_reconnect() {
        console.log('Reconnecting...');
    }

    on_message(topic, message, packet) {
        console.log('Received Message: ' + message + '\nOn topic: ' + topic + 'packet: ' + packet);
    }

    send_spool(value, process, code, note_val) {
        console.log(value, process);
        const spool = value;
        const notes = note_val;

        const json = {
            "spool": spool,
            "process": process,
            "code": code,
            "notes": notes
        };

        const data_out = JSON.stringify(json);
        console.log(data_out);
        this.c.publish(this.topic, data_out);
    }
}
