export const mqtt_options = {
  port: 443,
  username: 'midland-lead',
  password: '-9-WDCuYSsm8I.oXh66R',
  keepalive: 60,
  protocolId: 'MQTT',
  protocolVersion: 4,
  clean: true,
  reconnectPeriod: 1000,
  connectTimeout: 30 * 1000,
  will: {
    topic: 'WillMsg',
    payload: 'Connection Closed abnormally..!',
    qos: 0,
    retain: false,
  },
};

const hostname = window.location.hostname;

const prefix_re = new RegExp("[a-z-]+");
const prefix_match = hostname.match(prefix_re);
let prefix = "";
let postfix = "";

const postfix_re = new RegExp("\\.(.+)");
const postfix_match = hostname.match(postfix_re);
if (prefix_match)
{prefix = prefix_match[0];}
if (postfix_match)
{postfix = postfix_match[0];}

const influx_str = "-influx";
const mqtt_str = "-mqtt";


export const host_url = "wss://" + prefix + mqtt_str + postfix;
export const topic_name = "spool";

export const influxdb_config = {
bucket: "spool_number",
host: "https://" + prefix + influx_str + postfix,
org: "devtank"
}

export const token = "X8dYrRb5DVaKk_xycRvNysaqGeHfIQDtW6zHfQkcU55Uvepof1K2Qxaf8s8snw0fp3oXboJ2TNZT8JSuHD0bxw=="


//LOCAL ENV TEST CONF

// export const mqtt_options = {
//   port: 8083,
//   username: 'ml_test',
//   password: 'mlpwd',
//   keepalive: 60,
//   protocolId: 'MQTT',
//   protocolVersion: 4,
//   clean: true,
//   reconnectPeriod: 1000,
//   connectTimeout: 30 * 1000,
//   will: {
//     topic: 'WillMsg',
//     payload: 'Connection Closed abnormally..!',
//     qos: 0,
//     retain: false,
//   },
// };

// export const host_url = "ws://10.121.5.10";
// export const topic_name = "test";


// export const token = "NJdgyOmc4atqh3ALuFMUOqe9YekMNoOmqMJhMFkOHAtl8SbJIZXmytVMU5JUa01BfX1A6HY8reo1wbM_d3Pmhw=="
// export const influxdb_config = {
//   bucket: "spool_number",
//   host: "http://10.121.5.10:8086",
//   org: "devtank"
// }
