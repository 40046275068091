export function create_csv_file(fields: string[], values: Array<Array<string|number|undefined>>) {

    const csv_rows: Array<string|number> = [];

    const headers = fields.join(',');
    csv_rows.push(headers);

    for (let i = 0; i < values.length; i++) {
        csv_rows.push(values[i].join(','));
      }

    return csv_rows.join('\n')
}

export function create_download_link(spool_csv: string, mtype: string, number: number|string|undefined) {
    const a = window.document.createElement('a');
    const name = mtype + "_" + number + ".csv"
    const dataStr = `data:text/csv;charset=utf-8,${encodeURIComponent(spool_csv)}`;
    a.setAttribute('href', dataStr);
    a.setAttribute('download', name);
    a.click();
}


export function convert_time_range(timerange_str: string) {
    const time_dict: Record<string, string> = {
        "1 Hour": "-1h",
        "2 Hours": "-2h",
        "5 Hours": "-5h",
        "12 Hours": "-12h",
        "24 Hours": "-1d",
        "48 Hours": "-2d",
        "1 Week": "-7d",
        "1 Month": "-30d",
        "2 Months": "-60d",
        "3 Months": "-90d",
        "6 Months": "-180d",
        "1 Year": "-1y"
    }

    const time_ = time_dict[timerange_str]

    return time_
}
