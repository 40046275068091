import React from 'react';
import { PanelData, PanelProps } from '@grafana/data';
import { SimpleOptions } from './types';
import './styles/styles.css';
import { mqtt_client_t } from './mqtt_client';
import { mqtt_options, host_url, topic_name } from './conf';
import { css } from '@emotion/css';
import { execute_spool_query, execute_code_query, execute_per_day_spool_query } from './influxdb_client';

const host = host_url;
const topic = topic_name;
const options = mqtt_options;


const client = new mqtt_client_t(host, topic, options);

interface Props extends PanelProps<SimpleOptions> { }


function code_dialog(code: string) {
    const cancelButton = document.getElementById("cancel") as HTMLButtonElement;
    const dialog = document.getElementById("btn-dialog") as HTMLDialogElement;
    const confirm = document.getElementById("confirm") as HTMLButtonElement;
    const e = document.getElementById("time-range") as HTMLSelectElement;

    dialog.showModal();
    const controller = new AbortController();

    cancelButton.addEventListener("click", () => {
        dialog.close();
        controller.abort();
    });

    confirm.addEventListener("click", () => {
        const sel = e.selectedIndex;
        const opt = e.options[sel];
        const start = opt.text;
        execute_code_query(start, code);
    }, { signal:controller.signal, once: true });

}

function spool_dialog(spool_num: string|undefined, start: string, end: string) {
    const cancelButton = document.getElementById("cancel") as HTMLButtonElement;
    const dialog = document.getElementById("btn-dialog") as HTMLDialogElement;
    const confirm = document.getElementById("confirm") as HTMLButtonElement;

    dialog.showModal();
    const controller = new AbortController();

    cancelButton.addEventListener("click", () => {
        dialog.close();
        controller.abort();
    });

    confirm.addEventListener("click", () => {
        execute_spool_query(start, end, spool_num);
    }, { signal:controller.signal, once: true });
}

function all_spool_dialog(start: string, end: string) {
    const cancelButton = document.getElementById("all-cancel") as HTMLButtonElement;
    const dialog = document.getElementById("all-btn-dialog") as HTMLDialogElement;
    const confirm = document.getElementById("all-confirm") as HTMLButtonElement;

    dialog.showModal();
    const controller = new AbortController();

    cancelButton.addEventListener("click", () => {
        dialog.close();
        controller.abort();
    });

    confirm.addEventListener("click", () => {
        execute_per_day_spool_query(start, end);
    }, { signal:controller.signal, once: true });
}

function clear_prod_status(start_btn: HTMLElement, serial: HTMLInputElement, code: HTMLInputElement, notes: HTMLTextAreaElement) {
        serial.value = "";
        code.value = "";
        notes.value = "";

        localStorage.setItem('spool', serial.value);
        localStorage.setItem('code', code.value);
        localStorage.setItem('notes', notes.value);
}

function prod_start() {

    const serial = document.getElementById("panel-serial-num-input-id") as HTMLInputElement;
    const start_btn = document.getElementById("panel-serial-num-btn-start-id") as HTMLElement;
    const code = document.getElementById("panel-serial-code-input-id") as HTMLInputElement;
    const notes = document.getElementById("panel-serial-notes-input-id") as HTMLTextAreaElement;

    const spool = serial.value;
    const code_val = parseFloat(code.value);
    const note_val = notes.value;
    if (spool.length && !isNaN(code_val)) {
        if (start_btn.innerText === "Start Production" && spool !== "") {
            localStorage.setItem("start_btn", "Finish Production");
            start_btn.innerText = "Finish Production";
            localStorage.setItem("disabled", "true");
            serial.disabled = true;
            code.disabled = true;
            client.send_spool(spool, "start", code_val, note_val);
        }
        else if (start_btn.innerText === "Finish Production") {
            start_btn.innerText = "Start Production";
            localStorage.setItem("disabled", "false");
            serial.disabled = false;
            code.disabled = false;
            localStorage.setItem("start_btn", "Start Production");
            client.send_spool(spool, "finish", code_val, note_val);
            clear_prod_status(start_btn, serial, code, notes);
        }
    }
}

function save_field() {
    const serial = document.getElementById("panel-serial-num-input-id") as HTMLInputElement;
    const code = document.getElementById("panel-serial-code-input-id") as HTMLInputElement;
    const notes = document.getElementById("panel-serial-notes-input-id") as HTMLTextAreaElement;

    localStorage.setItem('spool', serial.value);
    localStorage.setItem('code', code.value);
    localStorage.setItem('notes', notes.value);
}

function spool_config() {
    let start_btn = localStorage.getItem('start_btn') as string;
    const disabled = localStorage.getItem("disabled") as string;
    let disabled_bool;
    if (disabled === "true") {
        disabled_bool = true;
    }
    else {
        disabled_bool = false;
    }
    if (!start_btn) {
        start_btn = "Start Production"
    }

    return (
        <div className="panel-overview-div">
            <div className='panel-serial-num-div'>
                <label>
                    Spool
                </label><br />

                <input onChange={() => save_field()}
                    className='panel-serial-num-input'
                    id="panel-serial-num-input-id"
                    defaultValue={localStorage.getItem('spool') as string}
                    disabled={disabled_bool}>
                </input><br /><br />

                <label>
                    Code
                </label><br />

                <select className='panel-serial-code-input'
                    id='panel-serial-code-input-id'
                    onChange={() => save_field()}
                    disabled={disabled_bool}>
                    <option hidden className='hidden-option'>{localStorage.getItem('code') as string}</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>0.5</option>
                    <option>1.0</option>
                    <option>1.07</option>
                    <option>1.5</option>
                    <option>1.6</option>
                    <option>2.0</option>
                    <option>2.5</option>
                    <option>3.0</option>
                </select><br /><br />

                <label>
                    Notes
                </label><br />
                <textarea
                    className='panel-serial-notes-input'
                    id='panel-serial-notes-input-id'
                    onChange={() => save_field()}
                    rows={3} cols={50}>
                    {localStorage.getItem('notes')}
                </textarea><br /><br />

                <button className='panel-serial-num-btn-start'
                    id="panel-serial-num-btn-start-id"
                    onClick={() => prod_start()}>{start_btn}
                </button>
            </div>
        </div>
    );
}

function spool_analysis(data: PanelData, width: number, height: number) {
    const _arr = data?.series;
    const first_arr = _arr[0].fields;
    const scnd_arr = _arr[1].fields;
    let start_date = "";
    let end_date = "";
    let notes = "";
    if (scnd_arr[1].labels?.is_end) {
        end_date = new Date(scnd_arr[0].values.get(0)).toLocaleString()
    }
    if (first_arr[1].labels?.is_start) {
        start_date = new Date(first_arr[0].values.get(0)).toLocaleString()
    }
    if (scnd_arr[1].labels?.notes) {
        notes = scnd_arr[1].labels.notes
    }
    return (
        <div
            className={(
                css`
                width: ${width}px;
                height: ${height}px;
            `
            )}
        >
            <div>
                <table className='spool-analysis-table'>
                    <div>
                        <tbody>
                            <td className='spool-analysis-table'>
                                Code
                            </td>
                            <td className='spool-analysis-table'>
                                {scnd_arr[1].values.get(0)}
                            </td>
                            </tbody>

                        <tbody>
                            <td className='spool-analysis-table'>
                                Spool
                            </td>
                            <td className='spool-analysis-table'>
                                {scnd_arr[1].labels?.spool}
                            </td>
                            </tbody>

                            <tbody>
                            <td className='spool-analysis-table'>
                                Start
                            </td>
                            <td className='spool-analysis-table'>
                                {start_date}
                            </td>
                            </tbody>

                            <tbody>
                            <td className='spool-analysis-table'>
                                End
                            </td>
                            <td className='spool-analysis-table'>
                                {end_date}
                            </td>
                            </tbody>

                        <tbody>
                            <td className='spool-analysis-table'>
                                Notes
                            </td>
                            <td className='spool-analysis-table'>
                                {notes}
                            </td>
                        </tbody>
                    </div>

                </table>
            </div>
        </div>
    );
}

function analysis_export(data: PanelData) {
    const _arr = data?.series;
    let start_time;
    let end_time;

    const is_start = _arr[0].fields[1].labels?.is_start;

    if (is_start) {
        start_time = _arr[0].fields[0].values.get(0);
        end_time = _arr[1].fields[0].values.get(0);
    }
    else {
        start_time = _arr[1].fields[0].values.get(0);
        end_time = _arr[0].fields[0].values.get(0);
    }

    const spool_num = _arr[0].fields[1].labels?.spool;

    start_time -= 1000;
    end_time += 1000;

    const start = new Date(start_time).toISOString();
    const end = new Date(end_time).toISOString();


    return (
        <div>
            <dialog id="btn-dialog">
                <form method="dialog">
                    <p>
                        Download csv file for spool {spool_num}?
                    </p>
                    <div>
                        <button id="cancel" type="reset">Cancel</button>
                        <button id="confirm" type="submit">Confirm</button>
                    </div>
                </form>
            </dialog>

            <button className='analysis-export-btn'
                    title='Download data associated with the current spool to a csv file.'
                    id="analysis-export-btn-id"
                    onClick={() => spool_dialog(spool_num, start, end)}>Spool Export
            </button>
            <center><div id="loader" className="loader"></div></center>


        </div>
    );
}

function code_export(data: PanelData) {
    const _arr = data.series;
    const code = _arr[0].fields[1].values.get(0);

    return (
        <div>
            <dialog id="btn-dialog">
                <form method="dialog">
                    <p>
                        <label htmlFor="time-range">Time range:</label>
                        <select id="time-range" name="time-range">
                            <option></option>
                            <option>1 Hour</option>
                            <option>2 Hours</option>
                            <option>5 Hours</option>
                            <option>12 Hours</option>
                            <option>24 Hours</option>
                            <option>48 Hours</option>
                            <option>1 Week</option>
                            <option>1 Month</option>
                            <option>2 Months</option>
                            <option>3 Months</option>
                            <option>6 Months</option>
                            <option>1 Year</option>
                        </select>
                    </p>
                    <div>
                        <button id="cancel" type="reset">Cancel</button>
                        <button id="confirm" type="submit">Confirm</button>
                    </div>
                </form>
            </dialog>

            <button className='code-export-btn'
                title='Download data associated with the current code to a csv file.'
                id="code-export-btn-id"
                onClick={() => code_dialog(code)}>Code Export
            </button>
            <center><div id="loader" className="loader"></div></center>

        </div>
    );
}


function all_spool_export(data: PanelData) {
    const _arr = data;
    const from = _arr.timeRange.from.toISOString();
    const to = _arr.timeRange.to.toISOString();

    return (

        <div>
            <dialog id="all-btn-dialog">
                <form method="dialog">
                    <p>
                        Download csv file for all spools in current time range?
                    </p>
                    <div>
                        <button id="all-cancel" type="reset">Cancel</button>
                        <button id="all-confirm" type="submit">Confirm</button>
                    </div>
                </form>
            </dialog>

            <button className='all-spool-export-btn'
                title='Download data for all spools in the current time range.'
                id="all-spool-export-btn-id"
                onClick={() => all_spool_dialog(from, to)}>Export All Spool Data
            </button>
            <center><div id="loader" className="loader"></div></center>

        </div>
    );
}


export const lead_spool_panel: React.FC<Props> = ({ options, data, width, height }) => {

    switch (options.panel_type) {
        default:
            return null;
        case 'spool_config':
            return spool_config();
        case 'spool_analysis':
            return spool_analysis(data, width, height);
        case 'analysis_export':
            return analysis_export(data);
        case 'code_export':
            return code_export(data);
        case 'all_spool_export':
            return all_spool_export(data);
    }
}
