import { InfluxDB } from '@influxdata/influxdb-client'
import { influxdb_config, token } from './conf';

import { convert_time_range, create_download_link } from './csv_export'

const url = influxdb_config.host;
const org = influxdb_config.org;

const queryApi = new InfluxDB({ url, token }).getQueryApi(org);
const sensors = '["osm/00340049/measurements", "osm/002B0029/measurements", "osm/00350049/measurements", "osm/005D0068/measurements", "osm/002C0035/measurements"]'

let vals: Array<Array<string | number | undefined>> = [];

let start_time_no_spool: string | null = null;
let end_time_no_spool: string | null = null;
let all_spool_list: Array<string | number> = [];
let final_spool_csv: Array<Array<Array<(string | number | undefined)>>> = []

function spool_query(start: string, stop: string, spool: string | number | undefined) {
    return `
    import "experimental"

    template =
    from(bucket: "spool_number")
        |> range(start: ${start}, stop: ${stop})

    start = template
        |> filter(fn: (r) => r["_measurement"] == "spool_num")
        |> filter(fn: (r) => r["is_start"] == "True")
        |> min(column: "_time")
        |> keep(columns: ["_time", "_field", "token", "spool"])
        |> findRecord(fn: (key) => key.spool == "${spool}", idx: 0)

    stop = template
        |> filter(fn: (r) => r["_measurement"] == "spool_num")
        |> filter(fn: (r) => r["is_end"] == "True")
        |> max(column: "_time")
        |> filter(fn: (r) => r["token"] == start.token)
        |> keep(columns: ["_time", "_field", "token", "is_end", "is_start", "spool"])
        |> findRecord(fn: (key) => key.token == start.token, idx: 0)

    timerange =
    from(bucket: "sf")
        |> range(start: start._time, stop: stop._time)

    laser_avg = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] =~ /CL[0-9]+/)
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> mean(column: "_value")

    laser_1 = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CL1")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")

    laser_2 = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CL2")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")


    laser_3 = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CL3")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")


    laser_4 = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CL4")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")

    laser_5 = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CL5")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")

    laser_6 = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CL6")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")

    immers = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "FTA1")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> map(fn: (r) => ({ r with _value: (r._value -4.0)/16.0 * 100.0 }))
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> filter(fn: (r) => exists r._value)

    meters = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CNT2" or r["_field"] == "CNT1")
        |> filter(fn: (r) => r["_value"] != 0.)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> map(fn: (r) => ({ r with _value: float(v: r._value) * 1.256 / 8.0 }))
        |> cumulativeSum()
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")

    revs = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CNT2" or r["_field"] == "CNT1")
        |> filter(fn: (r) => r["_value"] != 0.)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> aggregateWindow(every: 1m, fn: sum, createEmpty: true)
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")

    temp_in = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "TMPX")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> map(fn: (r) => ({ r with _value: (r._value / 10.) }))


    temp_out = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "TMPY")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> map(fn: (r) => ({ r with _value: (r._value / 10.) }))

    weight = timerange
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "WGHT")
        |> aggregateWindow(every: 1m, fn: max, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> keep(columns: ["_time", "_value", "_field"])


    immers_j = join(
        tables: {immers: immers, laser_avg: laser_avg},
        on: ["_time"],
        method: "inner"
    )

    laser_1_j = join(
        tables: {immers_j: immers_j, laser_1: laser_1},
        on: ["_time"],
        method: "inner"
    )

    laser_2_j = join(
        tables: {laser_1_j: laser_1_j, laser_2: laser_2},
        on: ["_time"],
        method: "inner"
    )

    laser_3_j = join(
        tables: {laser_2_j: laser_2_j, laser_3: laser_3},
        on: ["_time"],
        method: "inner"
    )

    laser_4_j = join(
        tables: {laser_3_j: laser_3_j, laser_4: laser_4},
        on: ["_time"],
        method: "inner"
    )

    laser_5_j = join(
        tables: {laser_4_j: laser_4_j, laser_5: laser_5},
        on: ["_time"],
        method: "inner"
    )

    laser_6_j = join(
        tables: {laser_5_j: laser_5_j, laser_6: laser_6},
        on: ["_time"],
        method: "inner"
    )

    j_meters = join(
        tables: {laser_6_j: laser_6_j, meters: meters},
        on: ["_time"],
        method: "inner"
    )

    j_rev = join(
        tables: {j_meters: j_meters, revs: revs},
        on: ["_time"],
        method: "inner"
    )

    j_tmpin = join(
        tables: {j_rev: j_rev, temp_in: temp_in},
        on: ["_time"],
        method: "inner"
    )

    j_tmpout = join(
        tables: {j_tmpin: j_tmpin, temp_out: temp_out},
        on: ["_time"],
        method: "inner"
    )

    j_weight = join(
        tables: {j_tmpout: j_tmpout, weight: weight},
        on: ["_time"],
        method: "inner"
    )


    isDropped = (tables) => {
        columnsArray = tables
            |> columns()
            |> findColumn(fn: (key) => true, column: "_value")

        return length(arr: columnsArray) == 0
    }

    is_dropped = isDropped(tables: j_weight)

    if not is_dropped then j_weight else j_tmpout
`
}


function spool_base_query(start: string, stop: string, query: string) {

    return `
        spool_first =
        from(bucket: "spool_number")
            |> range(start:${start}, stop: ${stop})
            |> filter(fn: (r) => r["_measurement"] == "spool_num")
            |> keep(columns: ["_time", "_value", "spool", "_field", "is_start"])
            |> filter(fn: (r) => exists r.is_start)
            |> min(column: "_time")
            |> group(columns: ["_time", "_field"], mode:"by")
            |> findRecord(fn: (key) => key._field == "code", idx: 0)

        ${query}
    `
}

function last_day_spool_query(stop: string) {
    return `
        code = from(bucket: "spool_number")
        |> range(start: spool_first._time, stop: ${stop})
            |> filter(fn: (r) => r["_measurement"] == "spool_num")
            |> filter(fn: (r) => r["_field"] == "code")
            |> keep(columns: ["_time", "_value", "spool"])
            |> filter(fn: (r) => exists r._value)
            |> group(columns: ["_time", "token", "code"], mode:"by")
            |> yield()
    `
}

function base_query(start: string, code: number | string, query: string) {

    return `
    code_first =
    from(bucket: "spool_number")
        |> range(start:${start})
        |> filter(fn: (r) => r["_measurement"] == "spool_num")
        |> filter(fn: (r) => r["_value"] == ${code})
        |> keep(columns: ["_time", "_value", "spool", "_field", "is_start"])
        |> filter(fn: (r) => exists r.is_start)
        |> min(column: "_time")
        |> group(columns: ["_time", "_field"], mode:"by")
        |> findRecord(fn: (key) => key._field == "code", idx: 0)

    ${query}
`
}

function code_query(code: number | string) {
    return `
        code = from(bucket: "spool_number")
        |> range(start: code_first._time)
            |> filter(fn: (r) => r["_measurement"] == "spool_num")
            |> filter(fn: (r) => r["_field"] == "code")
            |> filter(fn: (r) => r["_value"] == ${code})
            |> keep(columns: ["_time", "_value", "spool"])
            |> filter(fn: (r) => exists r._value)
            |> group(columns: ["_time", "token", "code"], mode:"by")
            |> yield()
    `
}

function laser_query(start: string, stop: string) {
    return   `
    import "experimental"

    laser =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] =~ /CL[0-9]+/)
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> mean(column: "_value")
        |> yield()
    `
}

function laser1_query(start: string, stop: string) {
    return   `
    import "experimental"

    laser =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CL1")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> yield()
    `
}
function laser2_query(start: string, stop: string) {
    return   `
    import "experimental"

    laser =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CL2")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> yield()
    `
}
function laser3_query(start: string, stop: string) {
    return   `
    import "experimental"

    laser =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CL3")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> yield()
    `
}
function laser4_query(start: string, stop: string) {
    return   `
    import "experimental"

    laser =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CL4")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> yield()
    `
}
function laser5_query(start: string, stop: string) {
    return   `
    import "experimental"

    laser =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CL5")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> yield()
    `
}

function laser6_query(start: string, stop: string) {
    return   `
    import "experimental"

    laser =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CL6")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> yield()
    `
}

function immers_query(start: string, stop: string) {
    return `
    import "experimental"

    immers =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "FTA1")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> map(fn: (r) => ({ r with _value: (r._value -4.0)/16.0 * 100.0 }))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> filter(fn: (r) => exists r._value)
        |> yield()
    `
}

function meters_query(start: string, stop: string) {
    return `
    import "experimental"

    meters =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CNT2" or r["_field"] == "CNT1")
        |> filter(fn: (r) => r["_value"] != 0.)
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> map(fn: (r) => ({ r with _value: float(v: r._value) * 1.256 / 8.0 }))
        |> cumulativeSum()
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> yield()
    `
}


function drum_revs_query(start: string, stop: string) {
    return `
    import "experimental"

    revs =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "CNT2" or r["_field"] == "CNT1")
        |> filter(fn: (r) => r["_value"] != 0.)
        |> aggregateWindow(every: 1m, fn: sum, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> yield()
    `
}

function temp_in_query(start: string, stop: string) {
    return `
    import "experimental"

    temp_in =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "TMPX")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> map(fn: (r) => ({ r with _value: (r._value / 10.) }))
        |> yield()
    `
}

function temp_out_query(start: string, stop: string) {
    return `
    import "experimental"

    temp_out =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "TMPY")
        |> aggregateWindow(every: 1m, fn: mean, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> group(columns: ["_time"], mode:"by")
        |> map(fn: (r) => ({ r with _value: (r._value / 10.) }))
        |> yield()
    `
}

function weight_query(start: string, stop: string) {
    return `
    import "experimental"

    weight =
    from(bucket: "sf")
        |> range(start: ${start}, stop: ${stop})
        |> filter(fn: (r) => r["_measurement"] == "mqtt_consumer")
        |> filter(fn: (r) => r["_field"] == "WGHT")
        |> aggregateWindow(every: 1m, fn: max, createEmpty: true)
        |> filter(fn: (r) => contains(value: r["topic"], set: ${sensors}))
        |> experimental.fill(value: 0.0)
        |> keep(columns: ["_time", "_value", "_field"])
        |> yield()
    `
}




export const execute_code_query = async (start: string, code: number | string) => {
    const loader = document.getElementById('loader') as HTMLElement;
    loader.style.display = 'block';
    const start_time = convert_time_range(start);
    const c_query = code_query(code);
    const base_q = base_query(start_time, code, c_query);


    const fields = ["Code", "Time", "Spool Number", "Lead Thickness (mm)",  "Drum Immersion Level",
        "Meters Rolled Out", "Drum RPM", "Temperature In (°C)", "Temperature Out (°C)", "Lead Weight (kg)"];

    const final_csv: Array<Array<Array<(string | number)>>> = []
    let count = 0
    let start_spool;
    let stop;
    let spool;
    let code_v;
    try {
        for await (const { values, tableMeta } of queryApi.iterateRows(base_q)) {
            vals = [];
            const output = tableMeta.toObject(values);
            const my_list: Array<string | number> = [];
            if (count === 0) {
                start_spool = output._time;
                spool = output.spool;
                code_v = output._value;
                count += 1
            }
            else if (count === 1) {
                stop = output._time;
                const time = convert_time_to_local(output._time);
                const laser_q = laser_query(start_spool, stop);
                for await (const { values, tableMeta } of queryApi.iterateRows(laser_q)) {
                    const my_list: Array<string | number> = [];
                    const output = tableMeta.toObject(values);
                    const time = convert_time_to_local(output._time);
                    my_list.push(code_v, time, spool, output._value);
                    try {
                        vals.push(my_list);
                    }
                    catch (e) {
                        console.log(`Could not push ${output._value} to ${vals} with ${output._field}`);
                    }
                }

                let index = 0;
                const immers_q = immers_query(start_spool, stop);
                for await (const { values, tableMeta } of queryApi.iterateRows(immers_q)) {
                    const output = tableMeta.toObject(values);
                    try {
                        vals[index].push(output._value);
                    }
                    catch (e) {
                        console.log(`Could not push ${output._value} to ${vals} with ${output._field}`);
                    }
                    index += 1;
                }

                index = 0;
                const meters_q = meters_query(start_spool, stop);
                for await (const { values, tableMeta } of queryApi.iterateRows(meters_q)) {
                    const output = tableMeta.toObject(values);
                    try {
                        vals[index].push(output._value);
                    }
                    catch (e) {
                        console.log(`Could not push ${output._value} to ${vals} with ${output._field}`);
                    }
                    index += 1;
                }

                index = 0;
                const drum_revs_q = drum_revs_query(start_spool, stop);
                for await (const { values, tableMeta } of queryApi.iterateRows(drum_revs_q)) {
                    const output = tableMeta.toObject(values);
                    try {
                        vals[index].push(output._value);
                    }
                    catch (e) {
                        console.log(`Could not push ${output._value} to ${vals} with ${output._field}`);
                    }
                    index += 1;
                }

                index = 0;
                const temp_in_q = temp_in_query(start_spool, stop);
                for await (const { values, tableMeta } of queryApi.iterateRows(temp_in_q)) {
                    const output = tableMeta.toObject(values);
                    try {
                        vals[index].push(output._value);
                    }
                    catch (e) {
                        console.log(`Could not push ${output._value} to ${vals} with ${output._field}`);
                    }
                    index += 1;
                }

                index = 0;
                const temp_out_q = temp_out_query(start_spool, stop);
                for await (const { values, tableMeta } of queryApi.iterateRows(temp_out_q)) {
                    const output = tableMeta.toObject(values);
                    try {
                        vals[index].push(output._value);
                    }
                    catch (e) {
                        console.log(`Could not push ${output._value} to ${vals} with ${output._field}`);
                    }
                    index += 1;
                }

                index = 0;
                const weight_q = weight_query(start_spool, stop);
                for await (const { values, tableMeta } of queryApi.iterateRows(weight_q)) {
                    const output = tableMeta.toObject(values);
                    try {

                        vals[index].push(output._value);
                    } catch (e) {
                        vals[index].push(0.0);
                    }
                    index += 1;
                }
                count = 0;
                const end_list: Array<string | number> = [];
                end_list.push(code_v, time, spool);
                vals.push(my_list);
                final_csv.push(vals);
            }

        }
        const code_csv = create_code_csv_file(fields, final_csv);
        create_download_link(code_csv, "code", code);
    }
    catch (e) {
        console.log(e);
        loader.style.display = 'none';
    }
    loader.style.display = 'none';

}


function convert_time_to_local(time: any) {

    const date = new Date(time);

    // Format the date to London time
    const options = { timeZone: "Europe/London", hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const local_time = new Intl.DateTimeFormat('en-GB', options).format(date);
    return local_time.replace(', ', ' ');
}


async function query_loop(query: any, prev_index: number) {
    let index = 0;
    for await (const { values, tableMeta } of queryApi.iterateRows(query)) {
        const output = tableMeta.toObject(values);
        try {
            vals[index].push(output._value);
        }
        catch (e) {
            console.log(`Could not push ${output._value} to ${vals} with ${output._field}`);
        }
        index += 1;
    }
    console.log(`Previous index: ${prev_index}`);
    console.log(`Current index: ${index}`);
    if (index === 0) {
        for (let i = 0; i < prev_index; i += 1) {
            console.log(vals);
            if (vals.length > 0) {
                vals[i].push(0.0);
            }
        }
        return prev_index
    }
    prev_index = index;
    return prev_index;
}

export const execute_per_day_spool_query = async (start: string, stoptime: string) => {
    const loader = document.getElementById('loader') as HTMLElement;
    loader.style.display = 'block';
    const s_query = last_day_spool_query(stoptime);
    const base_q = spool_base_query(start, stoptime, s_query);

    const start_filename = convert_time_to_local(start);
    const stop_filename = convert_time_to_local(stoptime);


    const filename = `${start_filename} - ${stop_filename}`;
    const fields = ["Code", "Time", "Spool Number", "Lead Thickness (mm)", "Laser 1 Lead Thickness (mm)", "Laser 2 Lead Thickness (mm)",
    "Laser 3 Lead Thickness (mm)",  "Laser 4 Lead Thickness (mm)",  "Laser 5 Lead Thickness (mm)",   "Laser 6 Lead Thickness (mm)",  "Drum Immersion Level",
        "Meters Rolled Out", "Drum RPM", "Temperature In (°C)", "Temperature Out (°C)", "Lead Weight (kg)"];

    final_spool_csv = [];
    let count = 0
    let start_spool = null;
    let stop;
    let spool;
    let code_v;
    try {
        for await (const { values, tableMeta } of queryApi.iterateRows(base_q)) {
            const output = tableMeta.toObject(values);
            all_spool_list = [];
            if (start_spool === null && count === 0) {
                vals = [];
                await fill_csv_data_all_spools(start, output._time, 'No spool', 'No code');
                start_time_no_spool = null;
            }
            vals = [];
            if (count === 0) {
                start_spool = output._time;
                end_time_no_spool = start_spool;
                if (start_time_no_spool) {
                    spool = 'No spool';
                    code_v = 'No code';
                    await fill_csv_data_all_spools(start_time_no_spool, end_time_no_spool, spool, code_v);
                }
                spool = output.spool;
                code_v = output._value;
                count += 1
                console.log(`in start spool ${start_spool}`);
            }
            else if (count === 1) {
                stop = output._time;
                await fill_csv_data_all_spools(start_spool, stop, spool, code_v);
                count = 0;
            }

        }
        vals = [];
        await fill_csv_data_all_spools(stop, stoptime, 'No spool', 'No code');

        const all_spool_csv = create_code_csv_file(fields, final_spool_csv);
        create_download_link(all_spool_csv, "spools", filename);

    } catch (e) {
        console.log(e);
        vals = [];
        await fill_csv_data_all_spools(start, stoptime, 'No spool', 'No code');
        const all_spool_csv = create_code_csv_file(fields, final_spool_csv);
        create_download_link(all_spool_csv, "spools", filename);
        loader.style.display = 'none';
    }
    loader.style.display = 'none';
}

async function fill_csv_data_all_spools(start_time: any, stop: any, spool: any, code_v: any) {
    {
        start_time_no_spool = stop;
        const laser_q = laser_query(start_time, stop);
        console.log(`reached laser q start time: ${start_time}`);
        console.log(`reached laser q stop time: ${stop}`);
        for await (const { values, tableMeta } of queryApi.iterateRows(laser_q)) {
            all_spool_list = [];
            const output = tableMeta.toObject(values);
            const time = convert_time_to_local(output._time);
            all_spool_list.push(code_v, time, spool, output._value);
            vals.push(all_spool_list);
        }

        const laser1_q = laser1_query(start_time, stop);
        let prev_index = await query_loop(laser1_q, 0);

        const laser2_q = laser2_query(start_time, stop);
        prev_index = await query_loop(laser2_q, prev_index);

        const laser3_q = laser3_query(start_time, stop);
        prev_index = await query_loop(laser3_q, prev_index);

        const laser4_q = laser4_query(start_time, stop);
        prev_index = await query_loop(laser4_q, prev_index);

        const laser5_q = laser5_query(start_time, stop);
        prev_index = await query_loop(laser5_q, prev_index);

        const laser6_q = laser6_query(start_time, stop);
        prev_index = await query_loop(laser6_q, prev_index);

        console.log(`immers query`);
        const immers_q = immers_query(start_time, stop);
        prev_index = await query_loop(immers_q, prev_index);

        console.log(`meters query`);
        const meters_q = meters_query(start_time, stop);
        prev_index = await query_loop(meters_q, prev_index);

        console.log(`drum_revs_query`);
        const drum_revs_q = drum_revs_query(start_time, stop);
        prev_index = await query_loop(drum_revs_q, prev_index);

        const temp_in_q = temp_in_query(start_time, stop);
        prev_index = await query_loop(temp_in_q, prev_index);

        const temp_out_q = temp_out_query(start_time, stop);
        prev_index = await query_loop(temp_out_q, prev_index);

        const weight_q = weight_query(start_time, stop);
        prev_index = await query_loop(weight_q, prev_index);

        vals.push(all_spool_list)
        final_spool_csv.push(vals);
    }
}

export const execute_spool_query = async (start: string, stop: string, spool: number|string|undefined) => {
    const loader = document.getElementById('loader') as HTMLElement;
    loader.style.display = 'block';
    const s_query = spool_query(start, stop, spool);

    const fields = ["Spool", "Time", "Drum Immersion Level",
        "Meters Rolled Out", "Average Lead Thickness (mm)", "Laser 1 Lead Thickness (mm)",
        "Laser 2 Lead Thickness (mm)", "Laser 3 Lead Thickness (mm)", "Laser 4 Lead Thickness (mm)",
        "Laser 5 Lead Thickness (mm)",  "Laser 6 Lead Thickness (mm)", "Drum RPM",
        "Temperature In (°C)", "Temperature Out (°C)", "Lead Weight (kg)"];
    vals = [];
    let immers_v, meters_v, laser_avg_v, laser_1_v, laser_2_v, laser_3_v, laser_4_v, laser_5_v
    let laser_6_v, revs_v, tmpin_v, tmpout_v, weight_v;
    try {
        for await (const {values, tableMeta} of queryApi.iterateRows(s_query)) {
            const o = tableMeta.toObject(values);
            const my_list: Array<string|number|undefined> = []
            const time = convert_time_to_local(o._time);
            try {
                immers_v = o._value_immers
            } catch (error) {
                immers_v = 0.0;
            }
            try {
                meters_v = o._value_j_meters
            } catch (error) {
                meters_v = 0.0;
            }
            try {
                laser_avg_v = o._value_laser_avg
            } catch (error) {
                laser_avg_v = 0.0;
            }
            try {
                laser_1_v = o._value_laser_1_j
            } catch (error) {
                laser_1_v = 0.0;
            }
            try {
                laser_2_v = o._value_laser_2
            } catch (error) {
                laser_2_v = 0.0;
            }
            try {
                laser_3_v = o._value_laser_3_j
            } catch (error) {
                laser_3_v = 0.0;
            }
            try {
                laser_4_v = o._value_laser_4
            } catch (error) {
                laser_4_v = 0.0;
            }
            try {
                laser_5_v = o._value_laser_5_j
            } catch (error) {
                laser_5_v = 0.0;
            }
            try {
                laser_6_v = o._value_laser_6
            } catch (error) {
                laser_6_v = 0.0;
            }
            try {
                revs_v = o._value_revs
            } catch (error) {
                revs_v = 0.0;
            }
            try {
                tmpin_v = o._value_j_tmpin
            } catch (error) {
                tmpin_v = 0.0;
            }
            try {
                tmpout_v = o._value_temp_out
            } catch (error) {
                tmpout_v = 0.0;
            }
            try {
                weight_v = o._value
            } catch (error) {
                weight_v = 0.0;
            }

            my_list.push(spool, time, immers_v, meters_v, laser_avg_v,
                laser_1_v, laser_2_v, laser_3_v,
                laser_4_v, laser_5_v, laser_6_v,
                revs_v, tmpin_v, tmpout_v, weight_v);
            vals.push(my_list);
        }

        const spool_csv = create_spool_csv_file(fields, vals);
        create_download_link(spool_csv, "spool", spool);
    }
    catch (e) {
        console.log(e);
        loader.style.display = 'none';
    }
    loader.style.display = 'none';
}


export function create_code_csv_file(fields: string[], values: Array<Array<Array<(string | number | undefined)>>> = []) {

    const csv_rows: Array<string|number> = [];

    const headers = fields.join(',');
    csv_rows.push(headers);

    for (let v = 0; v < values.length; v++) {
        for (let i = 0; i < values[v].length; i++) {
            csv_rows.push(values[v][i].join(','));
          }
    }

    return csv_rows.join('\n')
}

export function create_spool_csv_file(fields: string[], values: Array<Array<string|number|undefined>>) {

    const csv_rows: Array<string|number> = [];

    const headers = fields.join(',');
    csv_rows.push(headers);

    for (let i = 0; i < values.length; i++) {
        csv_rows.push(values[i].join(','));
        }

    return csv_rows.join('\n')
}

